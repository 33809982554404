import React from 'react'

import './TrackBrowser.css'

const TrackBrowser = (props) => {

	return props.loadingState ?
	(<div className="trackBrowser"><center><img src="/loading_spinner.gif" alt="loading..." height='50%' width='50%' align='center' /></center></div>)
		:
	(
		<div className="trackBrowser">
		<h1>{!props.user ? props.range ? props.range + " - " : "Day - " : null}{props.user ? props.userName + "'s Tracks" : props.genre === "" ? "No Genre" : props.genre ? props.genre : "All Genres"} - {props.mood === "" ? "No Mood" : props.mood ? props.mood : "All Moods"}</h1>
		<div className="trackPlates">
		<ul>
		{props.tracks.map((track) => {
			return (
				<li key={track.id}>
					<div className="trackListing">
						<div className="artwork">
							<img onClick={() => props.trackClicked(track)} src={track.artwork['150x150']} alt='artwork'/>
							<div className="hoverContainer">
								<div className="hoverPlay" onClick={() => props.trackClicked(track)}>▶</div>
							</div>
						</div>
						<div className="trackInfo">
							<div className="trackArtistAndName">
								<p><a href={'http://audius.co/'+track.user.handle+'/'}>{track.user.name ? track.user.name : track.user.handle}</a> - <a href={'http://audius.co/'+track.user.handle+'/'+encodeURIComponent(track.title.replace(/ /g, '-'))+'-'+props.hashDecoder(track.id)+'/'}>{track.title}</a></p>
							</div>
							<div className="trackPlayCount">
								<p>({track.play_count})</p>
							</div>
						</div>
					</div>
				</li>
			);
		})}
		</ul>
		</div>
		</div>
	);
}


export default TrackBrowser
