import React from 'react'
import { Scrubber, Button } from '@audius/stems'

import './PlayingBar.css'

const PlayingBar = (props) => {

	return (
		<div className="playingBar">
			<div className="nowPlaying">
				<p><b>Now Playing:</b></p><span>&nbsp;</span>
					{!props.playingTrack ? "Nothing!" :
						[<img key="nowPlaying_img" src={props.playingTrack.artwork['150x150']} alt='artwork'/>,
							<span key="nowPlaying_span1">&nbsp;</span>,
							<a key="nowPlaying_a1" href={'http://audius.co/'+props.playingTrack.user.handle+'/'}>
								{props.playingTrack.user.name ? props.playingTrack.user.name :
									props.playingTrack.user.handle}</a>,
							<span key="nowPlaying_span2">&nbsp;-&nbsp;</span>,
							<a key="nowPlaying_a2" href={'http://audius.co/'+props.playingTrack.user.handle+'/'+encodeURIComponent(props.playingTrack.title.replace(/ /g, '-'))+'-'+props.hashDecoder(props.playingTrack.id)+'/'}>{props.playingTrack.title}</a>
						]
					}
			</div>
			<div className="playingControlsBar">
				<div className="playPauseButton">
				<Button text={props.playPauseButtonText} size="tiny" onClick={props.playPause} />
				</div>
			<div className="durationScrubber">
			<Scrubber
				mediaKey={props.playingTrack ? props.playingTrack.id : null}
				elapsedSeconds={props.elapsedTime}
				totalSeconds={props.playingAudio ? props.playingTrack.duration : null}
				isPlaying={props.playingAudio ? !props.playingAudio.paused : null}
				onScrubRelease={(seconds) => (props.playingAudio ? props.playingAudio.currentTime = parseInt(seconds) : null)}
			/>
			</div>
			<div className="volumeScrubber">
			<div className="volumeTitle">Volume<br /></div>
			<Scrubber
				mediaKey={props.playingTrack ? props.playingTrack.id : null}
				includeTimestamps={false}
				elapsedSeconds={props.playingAudio ? props.playingAudio.volume * 100 : 100}
				totalSeconds='100'
				isPlaying={false}
				onScrub={(level) => props.setVolumeLevel(level)}
				onScrubRelease={(level) => props.setVolumeLevel(level)}
			/>
			</div>
			</div>
		</div>
	);
}


export default PlayingBar
