import React, { useEffect, useState } from 'react'
import Hashids from 'hashids/cjs'
import MenuArea from '../MenuArea/MenuArea'
import PlayingBar from '../PlayingBar/PlayingBar'
import TrackBrowser from '../TrackBrowser/TrackBrowser'
//import { Scrubber, Button } from '@audius/stems'

import './BottomPlayer.css'

/* This list is a list of handles of accounts that:
 		* Mostly upload copyrighted or otherwise not-their-own work
		* Are not who they seem to be (celebrity impersonator accounts)
		* Otherwise do not belong (perverts in the kids section)
	If you've been included in this list and feel this is in error,
	please open an issue or PR in this repo and explain why. */
const scammer_handles = [
	'1990','soudogd','Duyenlina22','Thongtrandalat','cryptoace','RoadtoBitcoin','FirasDhouibi',
	'canalpay','Lonly_kid','nickname02','elsat','maimaibenanhem','2020airdrop1','staytruelorjiggy',
	'maimaibenanh1','hoainguyen21','Hot','Duyenlina','Therlbread','rwota','Tonime2','Dalat',
	'Andrezitol1','Sangking','Bentopro','Duyen22','TheMisir','bduda0','billie_eilish',
	'draynicolas1','kopimi','uploads','redo','HipHop247','CyberNukeEdits','OnyxHD',
	'Celosity','OmarEstrada1987','melly','xxxxtentacion','Electric_Hawk','nosteps',
	'demetro_news','trader_keci','Suykast','Ianqaz','mhamedabdelmou1','toptracks','rapleaks',
	'MagSun','xiaoxing2207','Nimos','justinbeiber','TheHighGround','EloniousVancity',
	'itsthomasaman','Tiwing','rones_lira','assis_anastacio','poseidon909','Iamdooboy',
	'driftabeatz','ichihashi08','h22061995','Gadonttv','bruhtomen1337','Sobs4Crypto',
	'hoainguyen22','BernardFety','sararey90353942','rggomes','CleanEdits','JoelmyB',
	'sonsdeafrica','Cyberthebaby','KayRemix','drmib','hoaido0205','paulos',
	'ahmad_osman2016','findbuzz','Marcosrippel','afrobeatz','getter','portaljbmusik',
	'therealNemani','Sky_net','Johneri22328619','maven_','Aps17','JL711','vidal_news',
	'YoYoPink','only11','DaSlickstanator','kaem_e','espiritismo','omurokur','Dastan',
	'oliveirajoaquim','RajeshRamdevRam','smolbean','frizman','test_tube','test_centre',
	'test_subject','test_ing','MusikBox','DJSEX','vitoumerstigers','pietroc365',
	'19091969','DavidM','ThorOdinson','Alibgn','mullerivan','Shigenogoro56Z',
	'RiccardoSanti16','rshankar','LOUDLABS','sawdood','DJBOSSCHIC','LuneL1nkZXCKING',
	'raphits','PAPIJAM1N','heheboi','cardi_b','Huncho74','ariana_grande','nba_youngboy',
	'pop_smoke','juice_wrld','dolcepino','LetMeDriveDaBus','jpizzlez','DefiAgainst',
	'Billie','stlikk','alok','Jeanette','Landonmays','thanhturau','zharifhadi',
	'fx23456','duong821','ademkhether','Robgibin','wris','Andalus','nachoroussy__',
	'tomguich','trapperz'
];

/* This function selects a random host (discovery provider) from the list
  	available at api.audius.co */
const selectHost = async () => {
	const sample = (arr) => arr[Math.floor(Math.random() * arr.length)]
	const res = await fetch('https://api.audius.co')
	const hosts = await res.json()
	return sample(hosts.data)
}

/* Player component */
const BottomPlayer = () => {
	const [tracks, setTracks] = useState(null);
	const [allTracks, setAllTracks] = useState(null);
	const [genre, setGenre] = useState(null);
	const [mood, setMood] = useState(null);
	const [host, setHost] = useState(null);
	const [playingTrack, setPlayingTrack] = useState(null);
	const [playingAudio, setPlayingAudio] = useState(null);
	const [range, setRange] = useState(null);
	const [elapsedTime, setElapsedTime] = useState(null);
	const [playingSecondsPoll, setPlayingSecondsPoll] = useState(null);
	const [playPausedPoll, setPlayPausedPoll] = useState(null);
	const [user, setUser] = useState(null);
	const [userName, setUserName] = useState(null);
	const [trackVolume, setTrackVolume] = useState(null);
	const [playPauseButtonText, setplayPauseButtonText] = useState("▶ / ❚❚");
	const [loadingState, setLoadingState] = useState(true);

	useEffect(() => {
		document.title = "BottomPlayer";
	});

	/* This effect watches genre and range, and will resubmit the query on either
	  	field changing */
	useEffect(() => {
		const fetchTracks = async () => {
			setLoadingState(true);
			const selectedHost = await selectHost();
			const res = await fetch(`${selectedHost}/v1/tracks/trending?time={range ? range : "Day"}&genre=${genre ? encodeURIComponent(genre) : ""}&app_name=BottomPlayerv1`);
			const json = await res.json();
			setHost(selectedHost);
			setUserName(null);
			setUser(null);
			if (!json.data) {
				setUserName(null);
				setUser(null);
				setGenre(null);
				setMood(null);
				fetchTracks();
			} else {
				setAllTracks(json.data);
				setLoadingState(false);
			}
		};
		fetchTracks();
	}, [genre, range]);

	/* This effect watches for the userName to change, and will fetch the ID for that userName */
	useEffect(() => {
		const fetchID = async () => {
			setLoadingState(true);
			const selectedHost = await selectHost();
			const res = await fetch(`${selectedHost}/v1/users/search?query=${encodeURIComponent(userName)}&app_name=BottomPlayerv1`);
			const json = await res.json();
			if (!json.data) {
				setUserName(null);
				setUser(null);
				setGenre(null);
				setMood(null);
			} else {
				setUser(json.data[0].id);
			}
			setHost(selectedHost);
		};
		if (userName) {
			fetchID();
		}
	}, [userName]);

	/* This effect watches for the user to change, and will fetch just that user's
			tracks */
	useEffect(() => {
		const fetchTracks = async () => {
			const selectedHost = await selectHost();
			const res = await fetch(`${selectedHost}/v1/users/${user}/tracks?app_name=BottomPlayerv1`);
			const json = await res.json();
			if (!json.data) {
				setUser(null);
				setUserName(null);
				setGenre(null);
				setMood(null);
			} else {
				setAllTracks(json.data);
			}
			setHost(selectedHost);
			setLoadingState(false);
		};
		if (user) {
			fetchTracks();
		}
	}, [user]);

	/* Helper function to turn hash IDs into database numbers, for
	  	converting track IDs (and names) into URLs */
	const decodeHashId = (id) => {
		const hashids = new Hashids('azowernasdfoia', 5);
		return hashids.decode(id);
	}

	/* Main onClick event for the track artwork, will perform necessary
	  	cleanup on already-playing audio before starting new audio,
	  	or pause if clicked audio is the same as playing audio */
	const trackClicked = (track) => {
		if (playingAudio) {
			playingAudio.pause();
			playingAudio.currentTime = 0;
			setPlayingAudio(null);
			setElapsedTime(null);
			clearInterval(playingSecondsPoll);
			clearInterval(playPausedPoll);
			setPlayingSecondsPoll(null);
			setPlayPausedPoll(null);
			setplayPauseButtonText("▶");
			if (playingTrack.id !== track.id) {
				playTrack(track);
			}
		} else {
			playTrack(track);
		}
	};

	/* playTrack function was split out so we would have a layer of
	 	control over the setPlayingTrack function. When that
		was happening in the onClick, it was leading to
		infinite loops because of the useEffect hooks */
	const playTrack = (track) => {
		setPlayingTrack(track);
		const id = track.id;
		const streamUrl = `${host}/v1/tracks/${id}/stream?app_name=BottomPlayerv1`
		const audio = new Audio(streamUrl);
		if (audio) {
			setPlayingAudio(audio);
			audio.volume = (trackVolume ? trackVolume : 1);
			// these action handlers are for hardware play/pause buttons,
			//	to update the play/pause button in this UI in step
			navigator.mediaSession.setActionHandler('play', () => {
				audio.play();
				setplayPauseButtonText("❚❚");
			});
			navigator.mediaSession.setActionHandler('pause', () => {
				audio.pause();
				setplayPauseButtonText("▶");
			});
			audio.play();
			// this poll updates the value the time scrubber in
			//	PlayingBar reads from
			setPlayingSecondsPoll(setInterval(() => {
				const newTime = audio.currentTime;
				setElapsedTime(newTime);
			},200));
			setplayPauseButtonText("❚❚");
		}
	};

	// playPause function was moved up from PlayingBar to
	//	resolve a bug in the display of the play/pause
	//	symbol which would occur upon starting a new track
	const playPause = () => {
		if (playingAudio) {
			if (!playingAudio.paused) {
				playingAudio.pause();
				setplayPauseButtonText("▶");
			} else {
				playingAudio.play();
				setplayPauseButtonText("❚❚");
			}
		}
	};

	// volumeSet function was moved up from PlayingBar to
	//	resolve a bug where, if the volume had been changed
	//	prior to starting a song, it would still default to
	//	100% until it was set again
	const volumeSet = (level) => {
		const volumeFloat = level/100.0;
		setTrackVolume(volumeFloat);
		if (playingAudio && !playingAudio.paused) {
			playingAudio.volume = volumeFloat;
		}
	};

	// Genre handler, will trigger a re-fetch since the fetchTracks handler
	//	watches for genre to change as well
	useEffect(() => {
		// Excluding user here, because we don't want this filtering to occur
		// 	if a user is selected
		if (allTracks && !user) {
			setMood(null);
			setTracks(allTracks
				.filter((track) => { return !scammer_handles.includes(track.user.handle) }) // filtering out scammers
				.filter((track) => { return (genre !== null ? track.genre === null ? "" === genre : track.genre === genre : true) }) // genre filter
				.sort((a,b) => { return b.play_count - a.play_count }) // descending
				.slice(0,10)) // top 10
			;
		}
	}, [genre, allTracks, user]);

	// Mood handler, will not trigger a re-fetch since fetchTracks is not
	//	watching this field
	useEffect(() => {
		if (allTracks) {
			setTracks(allTracks
				.filter((track) => { return !scammer_handles.includes(track.user.handle) }) // filtering out scammers
				.filter((track) => { return (mood !== null ? track.mood === null ? "" === mood : track.mood === mood : true) }) // mood filter
				.sort((a,b) => { return b.play_count - a.play_count }) // descending
				.slice(0,10)) // top 10
			;
		}
	}, [mood, allTracks]);

	// User handler, will not trigger a re-fetch since fetchTracks is not
	//	watching this field
	useEffect(() => {
		if (allTracks && user) {
			setTracks(allTracks
				.filter((track) => { return !scammer_handles.includes(track.user.handle) }) // filtering out scammers
				.sort((a,b) => { return b.play_count - a.play_count }) // descending
				.slice(0,10)) // top 10
			;
		}
	}, [user, allTracks]);

	// Lazy onClick handler for the Bottom Face link, could use this to
	// 	fetch any other user and display their tracks
	const showBottomFaceTracks = (e) => {
		if (e) {
			e.preventDefault();
		}
		setUserName("Bottom Face");
	};


	// Returned component for rendering
	return (
		<div className="BottomPlayer">
		<h1 className="BottomPlayerTitle">BottomPlayer</h1>
		<p>An Audius API player focused on supporting independent artists! Made with ♥ by <a href="#!" onClick={() => showBottomFaceTracks()}>Bottom Face</a> in 2021.</p>
		<PlayingBar playingAudio={playingAudio} playingTrack={playingTrack} hashDecoder={decodeHashId} elapsedTime={elapsedTime} setVolumeLevel={volumeSet} playPauseButtonText={playPauseButtonText} playPause={playPause} />
		<br />
		<MenuArea genreHandler={setGenre} moodHandler={setMood} rangeHandler={setRange} />
		<TrackBrowser tracks={tracks} user={user} range={range} userName={userName} genre={genre} mood={mood} trackClicked={trackClicked} hashDecoder={decodeHashId} loadingState={loadingState} />
		</div>
	);
}


export default BottomPlayer
